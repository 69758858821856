import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

class Changelog extends React.Component {
  render() {
    return (
      <div>
        <h2 className="text-success">Notes E FOR COLL (<FormattedMessage id="Version" /> {process.env.REACT_APP_VERSION})</h2>
        <h4>Mise à jour du 03/12/2024</h4>
        <ul>
          <li><strong>Application Web (v2.8.2)</strong>
            <ul>
              <li>
                Un récapitulatif de toutes les demandes de nouveaux DM est désormais présent depuis le tableau de bord (écran d'accueil)
                <span className="jira-issue">689</span>
              </li>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li>Suppresion des mails envoyés en double en cas de réemploi de la même adresse par l'admin et les collaborateurs pour les notifications sur les commandes<span className="jira-issue">692</span></li>
                  <li>Amélioration du contenu du mail récapitulatif concernant les demandes de nouveaux DM<span className="jira-issue">688</span></li>
                  <li><span className="jira-issue">687</span></li>
                  <li><span className="jira-issue">686</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 12/11/2024</h4>
        <ul>
          <li><strong>Application Web (v2.8.1)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li>Un récapitulatif des produits de la commande est désormais présent dans le corps du courriel de confirmation<span className="jira-issue">683</span></li>
                  <li><span className="jira-issue">681</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 22/10/2024</h4>
        <ul>
          <li><strong>Application Web (v2.8.0)</strong>
            <ul>
              <li>
                Possibilité de choisir le nombre de résultat à afficher par page (pagination)
                <span className="jira-issue pharma">680</span>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 17/10/2024</h4>
        <ul>
          <li><strong>Application Web (v2.7.4)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">679</span></li>
                  <li><span className="jira-issue">678</span></li>
                  <li><span className="jira-issue">667</span></li>
                  <li><span className="jira-issue">666</span></li>
                  <li><span className="jira-issue">663</span></li>
                  <li><span className="jira-issue">650</span></li>
                  <li><span className="jira-issue">610</span></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Application Tablette (v2.6.4)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue e4bizz">679</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 19/09/2024</h4>
        <ul>
          <li><strong>Application Web (v2.7.3)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">671</span></li>
                  <li><span className="jira-issue">669</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 26/08/2024</h4>
        <ul>
          <li><strong>Application Web (v2.7.2)</strong>
            <ul>
              <li>Simplification de la synchronisation éventuelle de l'ensemble des commandes ainsi que de leurs statuts en un seul clic.<span className="jira-issue e4bizz">664</span></li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 15/06/2024</h4>
        <ul>
          <li><strong>Application Web (v2.7.1)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue e4bizz">85</span></li>
                  <li><span className="jira-issue">662</span></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Application Tablette (v2.6.3)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue e4bizz">85</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 25/04/2024</h4>
        <ul>
          <li><strong>Application Web (v2.7.0)</strong>
            <ul>
              {/**
                <li>Interfaçage avec LOMACO ON LINE<span className="jira-issue">588</span>
                <ul>
                <li>Possibilité de lier les comptes clients</li>
                <li>Possibilité de lier les mercuriales</li>
                <li>Synchronisation automatique des états de commandes</li>
                </ul>
                </li>
                */}
              <li>Évolution de la nomenclature des références commandes<span className="jira-issue">643</span></li>
              <li>Permettre la suppression des documents individuels attachés à la fiche équipement<span className="jira-issue">599</span></li>
              <li>Permettre la suppression des visuels attachés à la fiche équipement<span className="jira-issue">584</span></li>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">645</span></li>
                  <li><span className="jira-issue">642</span></li>
                  <li><span className="jira-issue">641</span></li>
                  <li><span className="jira-issue">639</span></li>
                  <li><span className="jira-issue">637</span></li>
                  <li><span className="jira-issue">635</span></li>
                  <li><span className="jira-issue">634</span></li>
                  <li><span className="jira-issue">632</span></li>
                  <li><span className="jira-issue">631</span></li>
                  <li><span className="jira-issue">628</span></li>
                  <li><span className="jira-issue">618</span></li>
                  <li><span className="jira-issue">617</span></li>
                  <li><span className="jira-issue">615</span></li>
                  <li><span className="jira-issue">614</span></li>
                  <li><span className="jira-issue">611</span></li>
                  <li><span className="jira-issue">609</span></li>
                  <li><span className="jira-issue">603</span></li>
                  <li><span className="jira-issue">602</span></li>
                  <li><span className="jira-issue">597</span></li>
                  <li><span className="jira-issue">595</span></li>
                  <li><span className="jira-issue">593</span></li>
                  <li><span className="jira-issue">591</span></li>
                  <li><span className="jira-issue">590</span></li>
                  <li><span className="jira-issue">586</span></li>
                  <li><span className="jira-issue">585</span></li>
                  <li><span className="jira-issue">572</span></li>
                  <li><span className="jira-issue">566</span></li>
                  <li><span className="jira-issue">560</span></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Application Tablette (v2.6.2)</strong>
            <ul>
              <li>Permettre la suppression des documents individuels attachés à la fiche équipement<span className="jira-issue">599</span></li>
              <li>Permettre la suppression des visuels attachés à la fiche équipement<span className="jira-issue">584</span></li>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">618</span></li>
                  <li><span className="jira-issue">617</span></li>
                  <li><span className="jira-issue">572</span></li>
                  <li><span className="jira-issue">569</span></li>
                  <li><span className="jira-issue">565</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 22/02/2024</h4>
        <ul>
          <li><strong>Application Web (v2.6.3)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">626</span></li>
                  <li><span className="jira-issue">623</span></li>
                  <li><span className="jira-issue">622</span></li>
                  <li><span className="jira-issue">621</span></li>
                  <li><span className="jira-issue">600</span></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Application Tablette (v2.6.1)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">627</span></li>
                  <li><span className="jira-issue">626</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 20/11/2023</h4>
        <ul>
          <li><strong>Application Web (v2.6.2)</strong>
            <ul>
              <li>Correction d'une erreur empêchant certains utilisateurs de télécharger les PDF de maintenances<span className="jira-issue">578</span></li>
              <li>Correction de la gestion des demandes de nouveaux équipements orphelines (demandeur supprimé)<span className="jira-issue">577</span></li>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">587</span></li>
                  <li><span className="jira-issue">579</span></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Application Tablette (v2.6.0)</strong>
            <ul>
              <li>Correction de la gestion des demandes de nouveaux équipements orphelines (demandeur supprimé)<span className="jira-issue">577</span></li>
              <li>Dans l'inventaire, afficher le nom des résidents associés pour les DM concernés<span className="jira-issue">576</span></li>
              <li>Les interventions et maintenances des équipements associés sont désormais accessibles depuis la gestion des résidents<span className="jira-issue">573</span></li>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">587</span></li>
                  <li><span className="jira-issue">580</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 14/11/2023</h4>
        <ul>
          <li><strong>Application Web (v2.6.1)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">571</span></li>
                  <li><span className="jira-issue">568</span></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Application Tablette (v2.5.1)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">568</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 13/11/2023</h4>
        <ul>
          <li><strong>Application Web (v2.6.0)</strong>
            <ul>
              <li>Meilleure prise en charge de la validation interne des commandes<span className="jira-issue">561</span></li>
              <li>Refonte de la gestion des maintenances<span className="jira-issue">451</span><span className="jira-issue">559</span></li>
              <li>Meilleure gestion de l'ajout et de la suppression des visuels de familles dans les mercuriales<span className="jira-issue">381</span></li>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">558</span></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Application Tablette (v2.5.0)</strong>
            <ul>
              <li>Correction automatique de l'orientation des photos prises avec l'appareil<span className="jira-issue">564</span></li>
              <li>Refonte de la gestion des maintenances<span className="jira-issue">451</span><span className="jira-issue">559</span></li>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">567</span></li>
                  <li><span className="jira-issue">563</span></li>
                  <li><span className="jira-issue">558</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 20/10/2023</h4>
        <ul>
          <li><strong>Application Web (v2.5.1)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">556</span></li>
                  <li><span className="jira-issue">555</span></li>
                  <li><span className="jira-issue">522</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 16/10/2023</h4>
        <ul>
          <li><strong>Application Web (v2.5.0)</strong>
            <ul>
              <li>Correction d'une erreur pouvant bloquer l'import d'équipement dans certains contextes<span className="jira-issue">551</span></li>
              <li>Amélioration des demandes de nouveaux dispositifs<span className="jira-issue">549</span></li>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">552</span></li>
                  <li><span className="jira-issue">550</span></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Application Tablette (v2.4.0)</strong>
            <ul>
              <li>Correction d'une erreur empêchant la réalisation de maitenances dans certains contextes<span className="jira-issue">553</span></li>
              <li>Amélioration des demandes de nouveaux dispositifs<span className="jira-issue">549</span></li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 03/10/2023</h4>
        <ul>
          <li><strong>Application Web (v2.4.2)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">548</span></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Application Tablette (v2.3.2)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">548</span></li>
                  <li><span className="jira-issue">548</span></li>
                  <li><span className="jira-issue">547</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 02/10/2023</h4>
        <ul>
          <li><strong>Application Web (v2.4.1)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">540</span></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Application Tablette (v2.3.1)</strong>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">544</span></li>
                  <li><span className="jira-issue">541</span></li>
                  <li><span className="jira-issue">483</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 01/10/2023</h4>
        <ul>
          <li><strong>Application Web (v2.4.0)</strong>
            <ul>
              <li>Synchronisation automatique avec MUST G5 des états de commandes (en déploiement)<span className="jira-issue">500</span></li>
              <li>Refonte du processus pour les demandes de nouveaux équipements<span className="jira-issue">499</span><span className="jira-issue">518</span></li>
              <li>Permettre la suppression d'un compte collaborateur<span className="jira-issue">490</span></li>
              <li>Afficher la date de la commande dans les PDF<span className="jira-issue">480</span></li>
              {/* <li>Ajout de l'aide en ligne Beta<span className="jira-issue">480</span></li> */}
              <li>Possibilité de conserver l'historique des maintenances réalisées même si un dispositif est déplacé d'un établissement un autre<span className="jira-issue">440</span><span className="jira-issue">507</span></li>
              <li>Améliorer la sélection d'un résident à associer dans la fiche équipement<span className="jira-issue">428</span></li>
              <li>Permettre la suppression d'un compte client<span className="jira-issue">426</span></li>
              <li>Possibilité de classer et de modifier les points de contrôle pour les dispositifs<span className="jira-issue">422</span></li>
            </ul>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">534</span></li>
                  <li><span className="jira-issue">533</span></li>
                  <li><span className="jira-issue">532</span></li>
                  <li><span className="jira-issue">531</span></li>
                  <li><span className="jira-issue">530</span></li>
                  <li><span className="jira-issue">527</span></li>
                  <li><span className="jira-issue">525</span></li>
                  <li><span className="jira-issue">516</span></li>
                  <li><span className="jira-issue">514</span></li>
                  <li><span className="jira-issue">512</span></li>
                  <li><span className="jira-issue">511</span></li>
                  <li><span className="jira-issue">510</span></li>
                  <li><span className="jira-issue">508</span></li>
                  <li><span className="jira-issue">506</span></li>
                  <li><span className="jira-issue">506</span></li>
                  <li><span className="jira-issue">504</span></li>
                  <li><span className="jira-issue">497</span></li>
                  <li><span className="jira-issue">496</span></li>
                  <li><span className="jira-issue">494</span></li>
                  <li><span className="jira-issue">492</span></li>
                  <li><span className="jira-issue">491</span></li>
                  <li><span className="jira-issue">489</span></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Application Tablette (v2.3.0)</strong>
            <ul>
              <li>Améliorer la sélection d'un résident à associer dans la fiche équipement<span className="jira-issue">428</span></li>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">527</span></li>
                  <li><span className="jira-issue">525</span></li>
                  <li><span className="jira-issue">516</span></li>
                  <li><span className="jira-issue">514</span></li>
                  <li><span className="jira-issue">513</span></li>
                  <li><span className="jira-issue">511</span></li>
                  <li><span className="jira-issue">510</span></li>
                  <li><span className="jira-issue">488</span></li>
                  <li><span className="jira-issue">426</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 20/07/2023</h4>
        <ul>
          <li><strong>Application Web (v2.3.4)</strong>
            <ul>
              <li>Correction d'une erreur Empêchant l'affectation d'un dispositif à un résident par l'établissement<span className="jira-issue">485</span></li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 12/07/2023</h4>
        <ul>
          <li><strong>Application Web (v2.3.3)</strong>
            <ul>
              <li>Correction d'une erreur bloquant l'envoi de notifications de nouvelles commandes par email<span className="jira-issue">481</span></li>
            </ul>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">479</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 10/07/2023</h4>
        <ul>
          <li><strong>Application Web (v2.3.2)</strong>
            <ul>
              <li>Correction d'une erreur potentielle d'inversion des quantités commandées entre deux articles synchronisés avec MUST G5<span className="jira-issue">477</span></li>
            </ul>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">478</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 07/07/2023</h4>
        <ul>
          <li><strong>Application Web (v2.3.1)</strong>
            <ul>
              <li>Amélioration du design de l'encart des opérations promotionnelles<span className="jira-issue">470</span></li>
              <li>Dans le cas d'une duplication de commande, le caractère "urgent" est conservé sur les produits signalés<span className="jira-issue">461</span></li>
              <li>Permettre la suppression d'une maintenance pour un dispositif<span className="jira-issue">449</span></li>
              <li>Refonte de certains écrans relatifs à la gestion des interventions<span className="jira-issue">445</span></li>
              <li>Amélioration du fichier d'export sur les erreurs de synchronisation<span className="jira-issue">442</span></li>
              <li>Permettre l'impression des codes-barres à l'unité<span className="jira-issue">424</span></li>
              <li>Permettre au validateur d'une commande de la réviser<span className="jira-issue">414</span></li>
              <li>Prise en charge des déclinaisons MUST G5 en synchronisation pour les mercuriales<span className="jira-issue">413</span></li>
              <li>Mise à niveau de certaines interfaces<span className="jira-issue">455</span><span className="jira-issue">401</span></li>
            </ul>
            <ul>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">476</span></li>
                  <li><span className="jira-issue">475</span></li>
                  <li><span className="jira-issue">474</span></li>
                  <li><span className="jira-issue">472</span></li>
                  <li><span className="jira-issue">469</span></li>
                  <li><span className="jira-issue">468</span></li>
                  <li><span className="jira-issue">467</span></li>
                  <li><span className="jira-issue">463</span></li>
                  <li><span className="jira-issue">460</span></li>
                  <li><span className="jira-issue">458</span></li>
                  <li><span className="jira-issue">457</span></li>
                  <li><span className="jira-issue">447</span></li>
                  <li><span className="jira-issue">446</span></li>
                  <li><span className="jira-issue">441</span></li>
                  <li><span className="jira-issue">435</span></li>
                  <li><span className="jira-issue">431</span></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Application Tablette (v2.2.0)</strong>
            <ul>
              <li>Permettre l'effacement des signatures dans les maitenances<span className="jira-issue">473</span></li>
              <li>Suivi plus complet des demandes d'intervention<span className="jira-issue">471</span></li>
              <li>Amélioration de la détection de la connexion réseau<span className="jira-issue">462</span></li>
              <li>Permettre la suppression d'une maintenance pour un dispositif<span className="jira-issue">449</span></li>
              <li>Evolutions et correctifs mineurs
                <ul>
                  <li><span className="jira-issue">475</span></li>
                  <li><span className="jira-issue">474</span></li>
                  <li><span className="jira-issue">469</span></li>
                  <li><span className="jira-issue">467</span></li>
                  <li><span className="jira-issue">464</span></li>
                  <li><span className="jira-issue">447</span></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h4>Mise à jour du 14/02/2023</h4>
        <li><strong>Application Web (v2.2.0)</strong>
          <ul>
            <li>Correction de l'affichage de certains tarifs HT à quatre décimales<span className="jira-issue">432</span></li>
            <li>Correction d'un bug relatif à l'importation d'équipements<span className="jira-issue">431</span></li>
            <li>Permettre la notification par mail des clients ou collaborateurs à la création de leur compte<span className="jira-issue">425</span></li>
            <li>Evolutions et correctifs mineurs
              <ul>
                <li><span className="jira-issue">430</span></li>
                <li><span className="jira-issue">420</span></li>
                <li><span className="jira-issue">366</span></li>
              </ul>
            </li>
          </ul>
        </li>
        <li><strong>Application Tablette (v2.1.0)</strong>
          <ul>
            <li>Sécurisation d'une sortie inopinée de l'application tablette par utilisation du bouton retour Androïd<span className="jira-issue">412</span></li>
            <li>Les photos prises avec la tablette sont désormais aussi enregistrées automatiquement dans la galerie<span className="jira-issue">411</span></li>
            <li>Permettre de choisir d'ajouter des images soit depuis l'appareil photo soit depuis la galerie de la tablette dans la fiche d'un équipement<span className="jira-issue">410</span></li>
            <li>Evolutions et correctifs mineurs
              <ul>
                <li><span className="jira-issue">421</span></li>
              </ul>
            </li>
          </ul>
        </li>
        <h4>Mise à jour du 19/10/2022</h4>
        <ul>
          <li><strong>Application Web (v2.1.2)</strong>
            <ul>
              <li>Correction d'une régression empêchant de scinder les commandes<span className="jira-issue">418</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 2.1.1 (18/10/2022)</h4>
        <ul>
          <li>Correction d'un défaut d'affichage des devis et des interventions<span className="jira-issue">402</span></li>
          <li>Evolutions et correctifs mineurs
            <ul>
              <li><span className="jira-issue">416</span></li>
              <li><span className="jira-issue">409</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 2.1.0 (03/09/2022)</h4>
        <ul>
          <li>Mise à niveau du serveur et prise en charge des dernières versions Android</li>
          <ul>
            <span className="jira-issue e4bizz">39</span>
            <span className="jira-issue e4bizz">38</span>
            <span className="jira-issue e4bizz">37</span>
            <span className="jira-issue e4bizz">36</span>
            <span className="jira-issue e4bizz">35</span>
            <span className="jira-issue e4bizz">30</span>
            <span className="jira-issue e4bizz">28</span>
            <span className="jira-issue e4bizz">26</span>
          </ul>
          <li>Evolutions et correctifs mineurs
            <ul>
              <li><span className="jira-issue">406</span></li>
              <li><span className="jira-issue">405</span></li>
              <li><span className="jira-issue">404</span></li>
              <li><span className="jira-issue">403</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 2.0.1 (21/07/2022)</h4>
        <ul>
          <li>Correction d'un bug relatif aux quantités sur les déclinaisons de produits dans les paniers de commandes<span className="jira-issue">399</span></li>
          <li>Evolutions et correctifs mineurs
            <ul>
              <li><span className="jira-issue">400</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 2.0.0 (13/07/2022)</h4>
        <ul>
          <li>Refonte majeure de la gestion des mercuriales et des commandes
            <ul>
              <li>Permettre la modification des tarifs des produits de la mercuriale à la ligne<span className="jira-issue">384</span></li>
              <li>Permettre le traitement des commandes en reliquat sur des mercuriales mise à jour ou expirées<span className="jira-issue">111</span></li>
            </ul>
          </li>
          <li>Autres évolutions et correctifs mineurs
            <ul>
              <li><span className="jira-issue">398</span></li>
              <li><span className="jira-issue">396</span></li>
              <li><span className="jira-issue">395</span></li>
              <li><span className="jira-issue">394</span></li>
              <li><span className="jira-issue">392</span></li>
              <li><span className="jira-issue">391</span></li>
              <li><span className="jira-issue">387</span></li>
              <li><span className="jira-issue">386</span></li>
              <li><span className="jira-issue">382</span></li>
              <li><span className="jira-issue">380</span></li>
              <li><span className="jira-issue">379</span></li>
              <li>Correction d'une page blanche à l'affichage de la synthèse d'un parc client<span className="jira-issue">378</span></li>
              <li><span className="jira-issue">362</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 1.0.1 (21/04/2022)</h4>
        <ul>
          <li>Correction d'un défaut d'affichage des images produits pour certaines mercuriales<span className="jira-issue">375</span></li>
          <li>Le carrousel des images est masqué par la fenêtre modale dans la fiche équipement<span className="jira-issue">374</span></li>
          <li>Autres évolutions et correctifs mineurs
            <ul>
              <li><span className="jira-issue">372</span></li>
              <li><span className="jira-issue">365</span></li>
              <li><span className="jira-issue">364</span></li>
              <li><span className="jira-issue">363</span></li>
              <li><span className="jira-issue">360</span></li>
              <li><span className="jira-issue">356</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 1.0.1 (21/04/2022)</h4>
        <ul>
          <li>Correction d'un défaut d'affichage des images produits pour certaines mercuriales<span className="jira-issue">375</span></li>
          <li>Le carrousel des images est masqué par la fenêtre modale dans la fiche équipement<span className="jira-issue">374</span></li>
          <li>Autres évolutions et correctifs mineurs
            <ul>
              <li><span className="jira-issue">372</span></li>
              <li><span className="jira-issue">365</span></li>
              <li><span className="jira-issue">364</span></li>
              <li><span className="jira-issue">363</span></li>
              <li><span className="jira-issue">360</span></li>
              <li><span className="jira-issue">356</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 1.0.0 (13/04/2022)</h4>
        <ul>
          <li>Amélioration des performances sur la récupération de données
            <ul>
              <li>Compression de flux<span className="jira-issue">338</span></li>
              <li>Parcs clients<span className="jira-issue">331</span></li>
              <li>Maintenances<span className="jira-issue">332</span></li>
              <li>Mercuriales<span className="jira-issue">328</span></li>
              <li>Paniers<span className="jira-issue">347</span></li>
              <li>Commandes<span className="jira-issue">330</span></li>
            </ul>
          </li>
          <li>Afficher les visuels de déclinaisons produit si disponibles<span className="jira-issue">337</span></li>
          <li>Prise en compte de l'état provisoire des DM dont la maintenance est en attente de signature<span className="jira-issue">325</span></li>
          <li>Ajout de l'étage et la chambre aux champs disponibles dans l'import massif de matériels<span className="jira-issue">324</span></li>
          <li>Activation des notifications par mail sur validation d'une commande en attente<span className="jira-issue">318</span></li>
          <li>Correction d'une régression sur l'affichage de certains montants à plus de deux décimales<span className="jira-issue">308</span></li>
          <li>Gestion améliorée des pièces jointes en annexe des devis<span className="jira-issue">306</span></li>
          <li>Le validateur peut choisir de supprimer une commande en attente de validation<span className="jira-issue">287</span></li>
          <li>Les photos prises avec la tablette ne s'affichent pas correctement côté client<span className="jira-issue">283</span></li>
          <li>Correction d'une régression sur règle d'arrondi de TVA sur les commandes<span className="jira-issue">281</span></li>
          <li>Correction de la mise en page lorsque les images utilisées pour les miniatures sont au format portait<span className="jira-issue">327</span></li>
          <li>Refonte de la gestion des interventions côté PSDM<span className="jira-issue">196</span></li>
          <li>Refonte de la gestion des visuels produits (banque d'images)<span className="jira-issue">169</span></li>
          <li>Refonte de la syntèse du parc côté client<span className="jira-issue">129</span></li>
          <li>Affichage du nom du demandeur dans la liste des devis côté client<span className="jira-issue">124</span></li>
          <li>Correction d'une erreur de dates sur les mercuriales anticipées<span className="jira-issue">110</span></li>
          <li>Amélioration de l'affichage de certaines statistiques<span className="jira-issue">92</span></li>
          <li>Autres évolutions et correctifs mineurs
            <ul>
              <li><span className="jira-issue">357</span></li>
              <li><span className="jira-issue">355</span></li>
              <li><span className="jira-issue">354</span></li>
              <li><span className="jira-issue">353</span></li>
              <li><span className="jira-issue">351</span></li>
              <li><span className="jira-issue">350</span></li>
              <li><span className="jira-issue">346</span></li>
              <li><span className="jira-issue">345</span></li>
              <li><span className="jira-issue">344</span></li>
              <li><span className="jira-issue">343</span></li>
              <li><span className="jira-issue">342</span></li>
              <li><span className="jira-issue">341</span></li>
              <li><span className="jira-issue">336</span></li>
              <li><span className="jira-issue">335</span></li>
              <li><span className="jira-issue">334</span></li>
              <li><span className="jira-issue">333</span></li>
              <li><span className="jira-issue">330</span></li>
              <li><span className="jira-issue">329</span></li>
              <li><span className="jira-issue">323</span></li>
              <li><span className="jira-issue">316</span></li>
              <li><span className="jira-issue">305</span></li>
              <li><span className="jira-issue">304</span></li>
              <li><span className="jira-issue">296</span></li>
              <li><span className="jira-issue">279</span></li>
              <li><span className="jira-issue">277</span></li>
              <li><span className="jira-issue">195</span></li>
              <li><span className="jira-issue">172</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 0.9.0 (30/12/2021)</h4>
        <ul>
          <li>Amélioration du processus d'authentification unifiée pour l'application tablette
            <ul>
              <li><span className="jira-issue">313</span></li>
              <li><span className="jira-issue">312</span></li>
              <li><span className="jira-issue">311</span></li>
              <li><span className="jira-issue">310</span></li>
            </ul>
          </li>
          <li>Autres évolutions et correctifs mineurs
            <ul>
              <li><span className="jira-issue">309</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 0.8.0 (20/10/2021)</h4>
        <ul>
          <li>Interfaçage avec ORTHOP MUST G5<span className="jira-issue">232</span>
            <ul>
              <li>Possibilité de lier les comptes clients</li>
              <li>Possibilité de lier les mercuriales</li>
              <li>Possibilité d'envoyer automatiquement les commandes pour intégration dans MUST G5</li>
            </ul>
          </li>
          <li>Autres évolutions et correctifs mineurs
            <ul>
              <li><span className="jira-issue">303</span></li>
              <li><span className="jira-issue">302</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 0.7.2 (29/09/2021)</h4>
        <ul>
          <li>Possibilité de redéfinir l'état d'un matériel à la clôture d'une intervention<span className="jira-issue">259</span></li>
          <li>Le client peut désormais télécharger une commande ayant le statut "nouvelle"<span className="jira-issue">261</span></li>
          <li>Possibilité d'associer un établissement à plusieurs collaborateurs<span className="jira-issue">208</span></li>
          <li>Amélioration des performances sur la récupération des mercuriales<span className="jira-issue">199</span></li>
          <li>Autres évolutions et correctifs mineurs
            <ul>
              <li>Correction d'une erreur d'affichage de statut des commandes dans les mails<span className="jira-issue">288</span></li>
              <li>Ajout d'un contrôle manquant sur l'état du matériel à la création<span className="jira-issue">285</span></li>
              <li>Correction d'une règle d'arrondi de TVA sur les commandes<span className="jira-issue">281</span></li>
              <li>Correction de l'absence d'information sur la matériovigilance après scan de code-barre d'un DM<span className="jira-issue">298</span></li>
              <li><span className="jira-issue">299</span></li>
              <li><span className="jira-issue">297</span></li>
              <li><span className="jira-issue">286</span></li>
              <li><span className="jira-issue">274</span></li>
              <li><span className="jira-issue">256</span></li>
              <li><span className="jira-issue">254</span></li>
              <li><span className="jira-issue">247</span></li>
              <li><span className="jira-issue">246</span></li>
              <li><span className="jira-issue">240</span></li>
              <li><span className="jira-issue">239</span></li>
              <li><span className="jira-issue">238</span></li>
              <li><span className="jira-issue">229</span></li>
              <li><span className="jira-issue">176</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 0.7.1 (15/06/2021)</h4>
        <ul>
          <li>Correction d'une erreur empêchant la modification d'un type de DM<span className="jira-issue">255</span></li>
        </ul>
        <h4>Version 0.7.0 (11/06/2021)</h4>
        <ul>
          <li>Parc
            <ul>
              <li>Possibilité de récupérer un équipement du PSDM installé dans un établissement pour ensuite l'assigner à un autre<span className="jira-issue">128</span><span className="jira-issue">236</span></li>
              <li>Comme pour les mercuriales, les équipements peuvent désormais être importés à partir d'un modèle Excel<span className="jira-issue">68</span></li>
              <li>Les maintenances de DM peuvent désormais être signées par lot et/ou en différé depuis la tablette<span className="jira-issue">66</span></li>
            </ul>
          </li>
          <li>Configuration
            <ul>
              <li>L'onglet "Frais &amp; budget" a été renommé en "Gestion des frais"<span className="jira-issue">209</span></li>
              <li>Ajout de la suppression des libellés pour les types de collaborateurs<span className="jira-issue">220</span></li>
              <li>Gestion complète des marques et des modèles<span className="jira-issue">242</span><span className="jira-issue">177</span></li>
            </ul>
          </li>
          <li>Commandes
            <ul>
              <li>La suppression des commandes en cours de traitement est désactivée pour le client<span className="jira-issue">204</span></li>
              <li>Ajout explicite d'une colonne "établissement" dans le tableau récapitulatif des commandes<span className="jira-issue">205</span></li>
              <li>A l'étape de validation, un message explicite indique au client le solde manquant en cas de minimum de commande imposé<span className="jira-issue">216</span></li>
            </ul>
          </li>
          <li>Devis
            <ul>
              <li>Correction d'une erreur empêchant la suppression de devis par le PSDM<span className="jira-issue">219</span>
              </li></ul>
          </li>
          <li>Autres évolutions et correctifs mineurs
            <ul>
              <li><span className="jira-issue">253</span></li>
              <li><span className="jira-issue">252</span></li>
              <li><span className="jira-issue">243</span></li>
              <li><span className="jira-issue">241</span></li>
              <li><span className="jira-issue">237</span></li>
              <li><span className="jira-issue">235</span></li>
              <li><span className="jira-issue">234</span></li>
              <li><span className="jira-issue">230</span></li>
              <li><span className="jira-issue">224</span></li>
              <li><span className="jira-issue">221</span></li>
              <li><span className="jira-issue">218</span></li>
              <li><span className="jira-issue">217</span></li>
              <li><span className="jira-issue">210</span></li>
              <li><span className="jira-issue">207</span></li>
              <li><span className="jira-issue">206</span></li>
              <li><span className="jira-issue">201</span></li>
              <li><span className="jira-issue">200</span></li>
              <li><span className="jira-issue">171</span></li>
              <li><span className="jira-issue">151</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 0.6.2 (22/03/2021)</h4>
        <ul>
          <li>Correction d'un filtre inopérant sur la localisation de DM côté client<span className="jira-issue">197</span>
          </li></ul>
        <h4>Version 0.6.1 (17/03/2021)</h4>
        <ul>
          <li>Correction d'une régression sur la création de marques et de modèles de DM<span className="jira-issue">194</span>
          </li><li>Les demandes de maintenance et/ou intervention ne peuvent concerner que les DM du PSDM ou de
            l'établissement<span className="jira-issue">159</span>
          </li>
          <li>Autres correctifs mineurs
            <ul>
              <li><span className="jira-issue">162</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 0.6.0 (16/03/2021)</h4>
        <ul>
          <li>Améliorer la gestion du résident<span className="jira-issue">85</span>
            <ul>
              <li>Le PSDM peut également désormais gérer les résidents des collectivités <span className="jira-issue">138</span></li>
              <li>Amélioration de l'affichage de la liste<span className="jira-issue">72</span></li>
              <li>Tri par ordre alphabétique<span className="jira-issue">70</span></li>
              <li>Visualisation des matériels éventuellement associés<span className="jira-issue">59</span></li>
              <li>Gestion du genre (M./Mme)<span className="jira-issue">140</span></li>
              <li>Ajout de la capacité d'accueil dans la fiche client<span className="jira-issue">131</span></li>
              <li>Ajout du nombre de residents déclarés dans la fiche client<span className="jira-issue">196</span>
              </li>
            </ul>
          </li>
          <li>Gestion de la configuration
            <ul>
              <li>Correction d'un filtre d'affichage dans la liste les mercuriales <span className="jira-issue">113</span></li>
              <li>Déplacement du menu collaborateur dans la configuration et gestion de la suppression du libellé
                "type"<span className="jira-issue">60</span></li>
              <li>Ajout d'une capacité d'accueil dans la configuration de l'établissement<span className="jira-issue">131</span></li>
            </ul>
          </li>
          <li>Gestion des équipements
            <ul>
              <li>La référence interne d'un équipement est désormais modifiable<span className="jira-issue">132</span>
              </li>
              <li>Dans la vue parc, affichage le cas échéant du nom du résident au survol de l'icône
                "Affectation"<span className="jira-issue">139</span></li>
              <li>Création d'un menu "actions" pour regrouper les actions générales dans le parc<span className="jira-issue">149</span></li>
              <li>Refonte graphique de la sythèse du parc côté client<span className="jira-issue">120</span></li>
            </ul>
          </li>
          <li>Mercuriales
            <ul>
              <li>Réorganisation de l'interface pour l'administrateur PSDM<span className="jira-issue">126</span>
              </li><li>Refonte du moteur de recherche et de l'affichage des produits<span className="jira-issue">142</span><span className="jira-issue">164</span>
              </li>
              <li>Ajout de la gestion des produits favoris<span className="jira-issue">99</span>
              </li>
              <li>Correction de la gestion du taux de TVA en fonction du format trouvé dans le fichier
                d'import<span className="jira-issue">102</span>
              </li>
            </ul>
          </li>
          <li>Gestion des maintenances et des interventions<span className="jira-issue">73</span>
            <ul>
              <li>Lorsque la collectivité demande le retour du DM, préciser s'il s'agit d'un remplacement ou d'une
                restitution<span className="jira-issue">75</span><span className="jira-issue">137</span></li>
              <li>Refonte des courriels envoyés sur les demandes d'intervention ou de renouvellement<span className="jira-issue">136</span></li>
              <li>Aucune nouvelle demande d'intervention ne peut être faite par le client sur un DM ayant déjà une
                demande en cours<span className="jira-issue">148</span></li>
              <li>Ajout des informations d'identification de l'établissement et du DM dans l'entête des demandes
                d'intervention<span className="jira-issue">115</span></li>
            </ul>
          </li>
          <li>Refonte de la matériovigilance<span className="jira-issue">77</span></li>
          <li>Correctifs mineurs
            <ul>
              <li><span className="jira-issue">49</span></li>
              <li><span className="jira-issue">57</span></li>
              <li><span className="jira-issue">94</span></li>
              <li><span className="jira-issue">97</span></li>
              <li><span className="jira-issue">114</span></li>
              <li><span className="jira-issue">118</span></li>
              <li><span className="jira-issue">134</span></li>
              <li><span className="jira-issue">135</span></li>
              <li><span className="jira-issue">147</span></li>
              <li><span className="jira-issue">156</span></li>
              <li><span className="jira-issue">157</span></li>
              <li><span className="jira-issue">160</span></li>
              <li><span className="jira-issue">161</span></li>
              <li><span className="jira-issue">162</span></li>
              <li><span className="jira-issue">167</span></li>
              <li><span className="jira-issue">170</span></li>
            </ul>
          </li>
        </ul>
        <h4>Version 0.5.0 (17/12/2020)</h4>
        <ul>
          <li>Amélioration du processus d'import des mercuriales</li>
          <li>Correction du tri et du classement des DM dans le parc</li>
        </ul>
        <h4>Version 0.4.0 (26/08/2020)</h4>
        <ul>
          <li>Simplification des modalités de connexion (mémorisation d'identifiant, accès direct pour les clients)
          </li>
          <li>Consolidation de l'interface graphique entre prestataire et client</li>
          <li>Ajout d'indicateurs visuels (codes couleurs) sur les statuts</li>
          <li>Amélioration de l'affichage des tableaux et de la pagination</li>
          <li>Ajout de fonctionalités de filtrage de résultats</li>
          <li>Ajout d'une confirmation pour le validateur lors de la validation d'une commande</li>
          <li>Ajout de la sélection du RDC dans les étages</li>
        </ul>
        <h4>Version 0.2.7 (10/06/2020)</h4>
        <ul>
          <li>Correctifs d'affichage mineurs</li>
        </ul>
        <h4>Version 0.2.6 (08/06/2020)</h4>
        <ul>
          <li>Diffusion des notes de version</li>
        </ul>
        <h4>Version 0.2.5 (08/06/2020)</h4>
        <ul>
          <li>Amélioration du processus d'importation des mercuriales et du système de notification en cas d'erreur
          </li>
        </ul>
        <h4>Version 0.2.4 (29/05/2020)</h4>
        <ul>
          <li>Amélioration de la gestion de la session permettant de rafraichir la page sans être déconnecté de
            l'application</li>
          <li>Correctifs mineurs</li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    establishmentSettings: state.establishmentSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Changelog));
